import { mapValues, toLower, isEmpty } from 'lodash';

export default {
  config: (state) => state.config,
  gameConfig: (state) => state.gameConfig,
  gameType: (state) => state.gameConfig.bettingType,
  gameColor: (state) => state.gameConfig.gameColor,
  betslipTypes: (state) => state.gameConfig.betslipTypes,
  isSystemBet: (state) => state.gameConfig.systemBet,
  isFutureBetActive: (state) => state.gameConfig.futureBet,
  futureInput: (state) =>
    state.gameConfig.additionalBettingInputs.filter((input) => input.betType === 'future')[0] || {},
  shortcuts: (state, getters) => {
    const { shortcuts } = state.gameConfig;
    return mapValues(shortcuts, (shortcut) => ({
      ...shortcut,
      name: getters.getTranslation(shortcut.name) || shortcut.name,
    }));
  },
  availableTicketActions: (state) => state.gameConfig.availableTicketActions,
  messaging: (state) => toLower(state.gameConfig.messaging),
  printLayoutType: (state) => state.gameConfig.printLayoutType,
  actionTypes: (state) => state.gameConfig.actionTypes,
  isAppLoaded: (getters) => !isEmpty(getters.gameConfig) && !isEmpty(getters.config),
  ticketPreviewData: (state) => state.ticketPreviewData,
  ticketPreviewActive: (state) => state.ticketPreviewActive,
  betStatuses: (state) => state.betStatuses,
  ticketStatuses: (state) => state.ticketStatuses,
  ticketIcons: (state) => state.ticketIcons,
  ticketAction: (state) => state.ticketAction,
  eventInProgress: (state) => {
    const { eventName } = state;
    const events = ['idle'];
    return eventName && events.indexOf(eventName) < 0;
  },
  // TODO: check how to handle per game (and connection status)
  isBettingDisabled: (state) => state.bettingDisabled,
  connectionActive: (state) => state.connectionActive,
  isLongerOffline: (state) => state.connectionLostDuration > state.connectionOfflineLimit,
  connectionLostDuration: (state) => state.connectionLostDuration,
  getTranslation: (state) => (key) => state.translations[key] || key,
  eventId: (state) => state.eventId,
  roundId: (state) => +state.eventId,
  autoTicketCheckTimeout: (state) => state.gameConfig.autoTicketCheckTimeout,
  maxBetNumber: (state) => state.config.rules.maxBetNumber.value,
  minBetAmount: (state) => state.config.rules.minBetAmount.value,
  maxBetAmount: (state) => state.config.rules.maxBetAmount.value,
  ticketActionSuccessful: (state) => state.ticketActionSuccessful,
  autoPayout: (state) => state.autoPayout,
  deviceId: (state) => state.deviceId,
  user: (state) => state.config.user,
  token: (state) => state.token,
  printedTicketsByTicketCheck: (state) => state.printedTicketsByTicketCheck,
  ticketChecksInProgress: (state) => state.ticketChecksInProgress,
  ticketsScheduledToBeChecked: (state) => state.ticketsScheduledToBeChecked,
  ticketsPrintedByTicketUpdate: (state) => state.ticketsPrintedByTicketUpdate,
  gtm: (state, getters) => getters.config?.ui?.config?.gtm || {},
  activeNotifications: (state) => state.activeNotifications,
  upcomingEvents: (state) => state.upcomingEvents,
  futureRounds: (state) => +state.futureRounds,
  futureBetEnabled: (state) => state.gameConfig.additionalBettingInputs[0].enabled,
  totalFutureBetsCount: (state) => state.gamesBetslip.ticket.reduce((total, bet) => total + bet.numEvents, 0),
  connectionReconnectActive: (state) => state.connectionReconnectActive,
  shouldGetUpcomingEvents: (state) => state.shouldGetUpcomingEvents,
  reconnectLimitReached: (state) => state.reconnectAtemptCounter >= state.maxReconnectAtemptNumber,
  socketReInitInProgress: (state) => state.socketReInitInProgress,
};
