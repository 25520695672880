import { BusService } from '@nsftx/games-bus';
import { isEmpty, omit, each, isNil } from 'lodash';
// import { eventBus } from '@/utility';
import store from '@/store';
import { ticketCheck } from '@/utility';
import { ws } from '../api';

let busService;

export default {
  /**
   * Start Bus service
   */
  async init() {
    const { config, shortcuts, deviceId } = store.getters;
    const channels = `NSoft.InstanceId.${config.productInstanceUuid},NSoft.SubscriptionId.${config.productId},NSoft.Device.${deviceId}`;
    let grantCode;
    try {
      store.dispatch('setSocketReInitInProgress', true);
      const { code } = await ws.getCodes(channels);
      grantCode = code;
    } catch (error) {
      store.dispatch('setSocketReInitInProgress', false);
      console.warn('ERROR in getting WS grant code', error);
      return;
    }

    const settings = {
      environment: config.environment,
      platform: config.platform,
      platformName: config.platformName,
      productName: config.productName,
      // TODO: Need to change websocket channel for product
      productId: config.productId, // config.productInstanceUuid
      tenantId: config.tenantId,
      locale: config.locale,
      applicationName: config.applicationName,
      useCommonEventName: true,
      adapters: [
        {
          name: 'UWSAdapter',
          options: { maxRetryAttempts: 10, grantCode },
        },
      ],
      channels: [],
      clientMessageProtocol: config.messageProtocol,
      productInstanceId: config.productInstanceUuid,
    };

    try {
      busService = new BusService(omit(settings, ['channels']));

      each(settings.channels, (channel) => {
        busService.addChannel(channel.type, channel.name, channel.adapter);
      });
      // eslint-disable-next-line no-console
      console.log('Starting Bus service.');
      busService.start();
      // t/8693pgvvc
      store.state.busServiceInit = true;
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Bus service error!', error);
    }

    busService.sendMessage(
      {
        action: 'Slave.Loaded',
        data: { shortcuts },
      },
      true,
    );
  },
  /**
   * Send message to bus service
   * @param {string} action
   * @param {object} data
   */
  sendMessage(action, data) {
    // eslint-disable-next-line no-console
    console.log(' sendMessage ', action);
    busService?.sendMessage(
      {
        action,
        data,
      },
      true,
    );
  },
  sendMessageAsync(action, data) {
    // eslint-disable-next-line no-console
    console.log(' sendMessageAsync ', action);
    try {
      busService?.clientAdapter.handler
        .emitAsync({
          action,
          data,
        })
        .then((response) => {
          // eslint-disable-next-line no-console
          console.log(' emitAsync response ', response);
          const { ticket } = response;
          store.dispatch('setTicketAction', ticket.action);
          if (ticket.localStatus === 'PENDING') {
            ticketCheck.startTicketChecker(ticket);
          } else {
            ticketCheck.stopTicketChecker(ticket);
          }
          /* const { ticket } = response;
        store.dispatch('setTicketAction', ticket.action);
        if (ticket.action === 'Add') {
          eventBus.$emit('ticketPayin', ticket);
        } else if (ticket.action === 'Cancel') {
          eventBus.$emit('ticketCancel', ticket);
        } else if (ticket.action === 'Payout') {
          eventBus.$emit('ticketPayout', ticket);
        } */
          store.dispatch('setTicketActionSuccessful', true);
        })
        .catch((error) => {
          // eslint-disable-next-line no-console
          console.log(' emitAsync error ', error);
          // Handle foreign roTax payout ticket, don't show that error
          // #869301c24
          if (error.code === '412') {
            store.dispatch('setTicketActionSuccessful', false);
          }
          if (!isEmpty(error) && error.code !== 'S_TAX_ABORTED' && error.code !== 'ERR_TICKET_VALIDATE_PAY') {
            store.dispatch('closeGGMessage');
            const message = store.getters.getTranslation(error.message) ?? error.message;
            store.dispatch('sendGGMessage', { message: message });
          }
        });
    } catch (error) {
      // 8694zura1
      // console.warn(error);
    }
  },
  addChannel(channelType, channelName) {
    busService.addChannel(channelType, channelName);
  },
  /**
   * Disconnect adapters during reconnect etc.
   */
  disconnectAdapters(message) {
    try {
      const uwsAdapter = busService.getAdapter('UWSAdapter');
      if (!isNil(uwsAdapter)) {
        uwsAdapter.disconnect(message);
      }
    } catch (error) {
      console.error('Unsuccesfull adapters disconnect', error);
    }
  },
};
