export default {
  SET_TICKET_PREVIEW_DATA: 'SET_TICKET_PREVIEW_DATA',
  SET_TICKET_PREVIEW_STATUS: 'SET_TICKET_PREVIEW_STATUS',
  SEND_GG_MESSAGE: 'SEND_GG_MESSAGE',
  SET_TICKET_ACTION: 'SET_TICKET_ACTION',
  SET_EVENT_TIME: 'SET_EVENT_TIME',
  SET_EVENT_ID: 'SET_EVENT_ID',
  SET_EVENT_NAME: 'SET_EVENT_NAME',
  SET_CONNECTION_STATUS: 'SET_CONNECTION_STATUS',
  SET_CONNECTION_LOST_TIME: 'SET_CONNECTION_LOST_TIME',
  RESET_CONNECTION_LOST_TIME: 'RESET_CONNECTION_LOST_TIME',
  SET_TRANSLATIONS: 'SET_TRANSLATIONS',
  SET_TICKET_ACTION_SUCCESSFUL: 'SET_TICKET_ACTION_SUCCESSFUL',
  SET_AUTO_PAYOUT: 'SET_AUTO_PAYOUT',
  SET_PAYOUT_CONFIRMATION: 'SET_PAYOUT_CONFIRMATION',
  SET_PAYOUT_CONFIRMATION_REPRINT: 'SET_PAYOUT_CONFIRMATION_REPRINT',
  SET_DEVICE_UUID: 'SET_DEVICE_UUID',
  SET_USER_TOKEN: 'SET_USER_TOKEN',
  SET_PRINTED_TICKET_BY_TICKET_CHECK: 'SET_PRINTED_TICKET_BY_TICKET_CHECK',
  REMOVE_PRINTED_TICKET_BY_TICKET_CHECK: 'REMOVE_PRINTED_TICKET_BY_TICKET_CHECK',
  SET_TICKET_CHECK_IN_PROGRESS: 'SET_TICKET_CHECK_IN_PROGRESS',
  REMOVE_TICKET_CHECK_IN_PROGRESS: 'REMOVE_TICKET_CHECK_IN_PROGRESS',
  SET_BETTING_DISABLED: 'SET_BETTING_DISABLED',
  UPDATE_ODDS_RULES: 'UPDATE_ODDS_RULES',
  SET_TICKET_TO_BE_CHECKED: 'SET_TICKET_TO_BE_CHECKED',
  REMOVE_TICKET_TO_BE_CHECKED: 'REMOVE_TICKET_TO_BE_CHECKED',
  ADD_PRINTED_TICKET_BY_TICKET_UPDATE: 'ADD_PRINTED_TICKET_BY_TICKET_UPDATE',
  UPDATE_PRINTED_TICKETS_ARRAY_BY_TICKET_UPDATE: 'UPDATE_PRINTED_TICKETS_ARRAY_BY_TICKET_UPDATE',
  ADD_ACTIVE_NOTIFICATION: 'ADD_ACTIVE_NOTIFICATION',
  REMOVE_ACTIVE_NOTIFICATION: 'REMOVE_ACTIVE_NOTIFICATION',
  SET_UPCOMING_EVENTS: 'SET_UPCOMING_EVENTS',
  SET_FUTURE_BET: 'SET_FUTURE_BET',
  SET_FUTURE_ROUNDS: 'SET_FUTURE_ROUNDS',
  SET_RECONNECT_STATE: 'SET_RECONNECT_STATE',
  UPDATE_SHOULD_GET_UPCOMING_EVENTS: 'UPDATE_SHOULD_GET_UPCOMING_EVENTS',
  INCREASE_RECONNECT_ATEMPTS: 'INCREASE_RECONNECT_ATEMPTS',
  RESET_RECONNECT_ATEMPTS_COUNTER: 'RESET_RECONNECT_ATEMPTS_COUNTER',
  SET_SOCKET_REINIT_IN_PROGRESS: 'SET_SOCKET_REINIT_IN_PROGRESS',
};
