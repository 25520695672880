import { isNil, merge } from 'lodash';
import { busService } from '@/utility';
import types from './mutationTypes';

export default {
  [types.SET_TICKET_PREVIEW_DATA](state, payload) {
    state.ticketPreviewData = payload || null;
  },
  [types.SET_TICKET_PREVIEW_STATUS](state, payload) {
    state.ticketPreviewActive = payload;
  },
  [types.SEND_GG_MESSAGE](state, payload) {
    const { action, data } = payload;
    busService.sendMessage(action, data);
  },
  [types.SET_TICKET_ACTION](state, payload) {
    state.ticketAction = payload;
  },
  [types.SET_EVENT_TIME](state, payload) {
    state.eventTime = payload;
  },
  [types.SET_EVENT_ID](state, payload) {
    state.eventId = payload;
  },
  [types.SET_EVENT_NAME](state, payload) {
    state.eventName = payload;
  },
  [types.SET_CONNECTION_STATUS](state, payload) {
    state.connectionActive = payload;
  },
  [types.SET_CONNECTION_LOST_TIME](state) {
    if (isNil(state.connectionLostTimestamp)) {
      state.connectionLostTimestamp = new Date().getTime();
      state.connectionLostDuration = 0;
      state.connectionLostInterval = setInterval(() => {
        const oldTime = (state.connectionLostTimestamp || new Date().getTime()) / 1000;
        const newTime = new Date().getTime() / 1000;

        state.connectionLostDuration = newTime - oldTime;
      }, 1000);
    }
  },
  [types.RESET_CONNECTION_LOST_TIME](state) {
    clearInterval(state.connectionLostInterval);
    state.connectionLostInterval = null;
    state.connectionLostTimestamp = null;
    state.connectionLostDuration = 0;
  },
  [types.SET_TRANSLATIONS](state, payload) {
    state.translations = merge({}, state.translations, payload);
  },
  [types.SET_TICKET_ACTION_SUCCESSFUL](state, payload) {
    state.ticketActionSuccessful = payload;
  },
  [types.SET_AUTO_PAYOUT](state, autoPayout) {
    state.autoPayout = autoPayout;
  },
  [types.SET_PAYOUT_CONFIRMATION](state, payoutConfirmation) {
    state.payoutConfirmation = payoutConfirmation;
  },
  [types.SET_PAYOUT_CONFIRMATION_REPRINT](state, payoutConfirmationReprint) {
    state.payoutConfirmationReprint = payoutConfirmationReprint.enabled;
  },
  [types.SET_DEVICE_UUID](state, device) {
    state.deviceId = device.uuid;
  },
  [types.SET_USER_TOKEN](state, token) {
    if (token) state.token = token;
  },
  [types.SET_PRINTED_TICKET_BY_TICKET_CHECK](state, ticket) {
    state.printedTicketsByTicketCheck.push(ticket);
  },
  [types.REMOVE_PRINTED_TICKET_BY_TICKET_CHECK](state, requestUuid) {
    const newArr = state.printedTicketsByTicketCheck.filter((ticket) => ticket.requestUuid !== requestUuid);
    state.printedTicketsByTicketCheck = newArr;
  },
  [types.SET_TICKET_TO_BE_CHECKED](state, ticket) {
    state.ticketsScheduledToBeChecked.push(ticket);
  },
  [types.REMOVE_TICKET_TO_BE_CHECKED](state, requestUuid) {
    const newArr = state.ticketsScheduledToBeChecked.filter((ticket) => ticket.requestUuid !== requestUuid);
    state.ticketsScheduledToBeChecked = newArr;
  },
  [types.ADD_PRINTED_TICKET_BY_TICKET_UPDATE](state, ticket) {
    state.ticketsPrintedByTicketUpdate.push(ticket);
  },
  [types.UPDATE_PRINTED_TICKETS_ARRAY_BY_TICKET_UPDATE](state, ticketsArray) {
    state.ticketsPrintedByTicketUpdate = ticketsArray;
  },
  [types.SET_TICKET_CHECK_IN_PROGRESS](state, ticket) {
    state.ticketChecksInProgress.push(ticket);
  },
  [types.REMOVE_TICKET_CHECK_IN_PROGRESS](state, requestUuid) {
    const newArr = state.printedTicketsByTicketCheck.filter((ticket) => ticket.requestUuid !== requestUuid);
    state.ticketChecksInProgress = newArr;
  },
  [types.SET_BETTING_DISABLED](state, bettingDisabled) {
    state.bettingDisabled = bettingDisabled;
  },
  [types.UPDATE_ODDS_RULES](state, maxPossibleMultiplier) {
    state.gameConfig.oddRules.maxOdd = maxPossibleMultiplier;
  },
  [types.ADD_ACTIVE_NOTIFICATION](state, notification) {
    state.activeNotifications.push(notification);
  },
  [types.REMOVE_ACTIVE_NOTIFICATION](state, notification) {
    state.activeNotifications = state.activeNotifications.filter(
      (activeNotification) => activeNotification !== notification,
    );
  },
  [types.SET_UPCOMING_EVENTS](state, upcomingEvents) {
    state.upcomingEvents = upcomingEvents;
  },
  [types.SET_FUTURE_BET](state, isActive) {
    state.gameConfig.futureBet = isActive;
  },
  [types.SET_FUTURE_ROUNDS](state, futureRounds) {
    state.futureRounds = futureRounds;
  },
  [types.SET_RECONNECT_STATE](state, value) {
    state.connectionReconnectActive = value;
  },
  [types.UPDATE_SHOULD_GET_UPCOMING_EVENTS](state, shouldUpdate) {
    state.shouldGetUpcomingEvents = shouldUpdate;
  },
  [types.INCREASE_RECONNECT_ATEMPTS](state) {
    state.reconnectAtemptCounter++;
  },
  [types.RESET_RECONNECT_ATEMPTS_COUNTER](state) {
    state.reconnectAtemptCounter = 0;
  },
  [types.SET_SOCKET_REINIT_IN_PROGRESS](state, value) {
    state.socketReInitInProgress = value;
  },
};
