import { eventBus } from '@/utility';
import store from '@/store';
// import store from '../store';
// LEFT IN IMPLEMENTATION FOR MULTIPLE TICKET CHECKS PER TICKET JUST EXPAND TICKET TIMEOUTS ARRAY WITH NEW TIMEOUTS
let ticketTimeouts = [15000];
let ticketTimeout = null;
/**
 *
 * @param {Object} ticket
 * Sends event to check ticket eather by barcode or request uuid based on if the param ticket has a valid barcode
 */
const checkTicket = async (ticket) => {
  if (ticket.id && ticket.id !== '-') {
    eventBus.$emit('ticketCheckByBarcode', ticket);
  } else {
    eventBus.$emit('ticketRequestIdCheck', ticket);
  }
};

/**
 * Checks if it should remove tickets from listOfTicketsToBeChecked array and removes them
 * If any ticket is scheduled don't clear the list.
 * This is done to avoid mutating array "listOfTicketsToBeChecked" while a ticket is scheduled for a check
 */
const clearListOfTicketsToBeChecked = () => {
  const { ticketsScheduledToBeChecked } = store.getters;

  ticketsScheduledToBeChecked.forEach((ticket) => {
    if (ticket.checkScheduled) return;
  });

  // Find all tickets that have reached check limit
  const limitReachedTickets = ticketsScheduledToBeChecked.filter((ticket) => ticket.checkLimitReached);
  if (!limitReachedTickets.length) return;

  limitReachedTickets.forEach((ticket) => {
    store.commit('REMOVE_TICKET_TO_BE_CHECKED', ticket.requestUuid);
  });
};

/**
 * Adds ticket to the array of tickets that should be checked if that ticket isn't already in the array.
 * @param {Object} ticket
 *
 */
const addTicketToBeChecked = (ticket) => {
  const { id, requestUuid } = ticket;
  const { ticketsScheduledToBeChecked } = store.getters;
  const ticketObject = {
    id,
    requestUuid,
    timesChecked: 0,
    checkScheduled: false,
    checkLimitReached: false,
  };
  if (
    !ticketsScheduledToBeChecked.some((ticket) => {
      return ticket.requestUuid === requestUuid;
    })
  ) {
    store.commit('SET_TICKET_TO_BE_CHECKED', ticketObject);
  }
};
/**
 * Starts ticket checker
 * @param {Object} ticket
 */
const startTicketChecker = (ticket) => {
  addTicketToBeChecked(ticket);
  clearListOfTicketsToBeChecked();

  const { ticketsScheduledToBeChecked } = store.getters;
  // Go through all tickets that should be checked
  // All tickets on payin get added to this array, once ticket update is received the ticket is removed
  ticketsScheduledToBeChecked.forEach((ticket) => {
    // Check if the ticket has ticket check Scheduled ( timeout function active ) and if the check limit is reached
    if (ticket.checkScheduled || ticket.checkLimitReached) return;
    // Set check scheduled to true ( create timeout for ticket check)
    ticket.checkScheduled = true;
    ticketTimeout = setTimeout(() => {
      checkTicket(ticket);
      // Check finished and update counter
      ticket.checkScheduled = false;
      ticket.timesChecked++;
      // If there is no timeout inside ticketTimeouts it means that check limit is reached
      ticket.checkLimitReached = !ticketTimeouts[ticket?.timesChecked];
      startTicketChecker(ticket);
    }, ticketTimeouts[ticket.timesChecked]);
  });
};
/**
 * Stops ticket checker
 * @param {Object} ticket
 */
const stopTicketChecker = (ticket) => {
  const { ticketsScheduledToBeChecked } = store.getters;
  if (!ticket) return;
  const { requestId, requestUuid, id } = ticket;
  ticketsScheduledToBeChecked.forEach((ticket) => {
    // REQUEST UUID IS USED TO FILTER PAYIN REQUESTS, TICKET ID IS USED TO FILTER PAYOUT AND CANCEL REQUESTS
    if (
      ticket.requestUuid === requestId ||
      ticket.requestUuid === requestUuid ||
      (ticket.id === id && ticket.id !== '-')
    ) {
      ticket.checkLimitReached = true;
      ticket.checkScheduled = false;
      clearTimeout(ticketTimeout);
    }
  });
};

export default { startTicketChecker, stopTicketChecker };
